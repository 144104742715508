.button{
    border: none;
    color:  white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.primary {background-color: #008cba;}
.secondary {background-color: #e7e7e7; color:black}
.success {background-color: #4caf50;}
.danger {background-color: #f44336;}